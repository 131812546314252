<template>
   <div class="row">
		<div class="col-12 col-lg-1 col-xl-2"></div>

		<div class="col-12 col-lg-10 col-xl-8">
			<div class="card mb-0">
            <div class="card-body py-5">
               <div class="row align-items-center">
                  <div class="col-12 col-md-6 text-center my-4">
                     <img src="@/assets/images/dashboard/guarda.png" class="w-75"> 
                  </div>
                  <div class="col-12 col-md-6 text-center my-4">
                     <i class="fal fa-exclamation-triangle font-90 color-theme mb-5 d-none d-md-block"></i>
                     <h4 class="mb-4">{{ $t("vipMessage") }}</h4>
                     <p class="mb-5 font-18"><i class="fal fa-star font-16 color-theme mr-2"></i>{{ $route.params.pack }}</p>
                     <router-link to="/Premium" class="btn btn-primary btn-lg btn-block font-20"><i class="far fa-long-arrow-right font-17 mr-3"></i>{{ $t("premium.seePlans") }}</router-link>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'PremiumRequired'
}

</script>